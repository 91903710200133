import io from 'socket.io-client';
import store from '@/store/store.js';



const socket = io("https://levana.anavel.bzh:4001")
console.log('cc', store.state.isAuthenticated)
if (store.state.isAuthenticated) {
  console.log("ok socket")

  socket.on("connect", () => {
    socket.emit("userConnected",
      `${store.state.user.lastname} ${store.state.user.firstname}`, store
      .state.user.id.toString())
  })
}

function sendNotification(idUser, type, title, action) {
  socket.emit("notification", {
    roomName: idUser,
    type: type,
    info: {
      title: title,
      action: action,
      datetime: new Date(),
    },
  })
}

export {
  socket,
  sendNotification
}
