import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './../store/store'
import {
  getCookie
} from '@/utils/cookies'

const APP_NAME = 'Mes Services Assurance'
import config from '@/views/config/config'

Vue.use(VueRouter)

const COLLABORATOR = 'collaborateur'
const ADMINISTRATOR = 'administrateur'
const GUEST = 'invite'
const MANAGER = 'manager';
const ACCOUNTANT = 'expert comptable';
const SOCIAL_OFFICE = 'cabinet social';
const MANAGER_DUE = 'manager due';
const SUPERADMIN = 'superadministrateur';


const routes = [{
    path: '/',
    redirect: 'auth/login',
    meta: {
      title: APP_NAME,
    }
  },
  {
    path: '/services/charges',
    name: 'serviceSuppliers',
    component: () => import('@/views/pages/services/charges/MySuppliers.vue'),
    meta: {
      title: `${APP_NAME} - Mes fournisseurs`,
      rights: [COLLABORATOR, ADMINISTRATOR, SUPERADMIN],

    },

  },
  {
    path: '/services/tools',
    name: 'serviceToolsPdf',
    component: () => import('@/views/pages/services/tools/PDFs/Dashboard.vue'),
    meta: {
      title: `${APP_NAME} - Outils PDF`,
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, SUPERADMIN],
    },
  },
  {
    path: '/payments/plan/success',
    name: 'success',
    component: () => import('@/views/pages/payments/plan/Success.vue'),
    meta: {
      title: `${APP_NAME} - Paiement réussi`,
      rights: [ADMINISTRATOR, MANAGER, SUPERADMIN],
      layout: 'blank',
    },
    props: route => ({
      sessionId: route.query.session_id,
    })

  },
  {
    path: '/payments/plan/cancel',
    name: 'cancel',
    component: () => import('@/views/pages/payments/plan/Cancel.vue'),
    meta: {
      title: `${APP_NAME} - Paiement annulé`,
      rights: [ADMINISTRATOR, MANAGER, SUPERADMIN],
      layout: 'blank',

    }
  },
  {
    path: '/payments/due/success',
    name: 'success',
    component: () => import('@/views/pages/payments/DUE/Success.vue'),
    meta: {
      title: `${APP_NAME} - Paiement réussi`,
      rights: [ADMINISTRATOR, MANAGER, SUPERADMIN],
      layout: 'blank',
    },
    props: route => ({
      sessionId: route.query.session_id,
      siret: route.query.siret
    })


  },
  {
    path: '/payments/due/cancel',
    name: 'cancel',
    component: () => import('@/views/pages/payments/DUE/Cancel.vue'),
    meta: {
      title: `${APP_NAME} - Paiement annulé`,
      rights: [ADMINISTRATOR, MANAGER, SUPERADMIN],
      layout: 'blank',

    }
  },
  {
    path: '/services/commissions',
    name: 'serviceCommissions',
    component: () => import('@/views/pages/services/commissions/Commissions.vue'),
    meta: {
      title: `${APP_NAME} - Mes commissions`,
      rights: [ADMINISTRATOR, SUPERADMIN],
    }

  },

  {
    path: '/services/products',
    name: 'serviceProducts',
    component: () => import('@/views/pages/services/products/Products.vue'),
    meta: {
      title: `${APP_NAME} - Mes produits`,
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, SUPERADMIN],
    }

  },

  {
    path: '/services/products/items',
    name: 'serviceProductsItems',
    component: () => import('@/views/pages/services/products/ProductsItems.vue'),
    meta: {
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, SUPERADMIN],
    }

  },

  {
    path: '/services/partners',
    name: 'servicePartners',
    component: () => import('@/views/pages/services/partners/Partners.vue'),
    meta: {
      title: `${APP_NAME} - Mes partenaires`,
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, SUPERADMIN],
    }

  },

  {
    path: '/services/partners/items',
    name: 'servicePartnersItems',
    component: () => import('@/views/pages/services/partners/PartnersItems.vue'),
    meta: {
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, SUPERADMIN],
    }

  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/pages/services/contact/Contact.vue'),
    meta: {
      title: `${APP_NAME} - Demandes de rappel`,
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, SUPERADMIN],

    }

  },
  {
    path: '/colleague',
    name: 'colleague',
    component: () => import('@/views/pages/services/colleague/Colleague.vue'),
    meta: {
      title: `${APP_NAME} - Mes collègues`,
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, SUPERADMIN],

    }

  },
  {
    path: '/services/lddc',
    name: 'serviceLddc',
    component: () => import('@/views/pages/services/generator/Lddc.vue'),
    meta: {
      title: `${APP_NAME} - LDDC`,
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, SUPERADMIN],

    }

  },
  {
    path: '/services/due',
    name: 'serviceDue',
    component: () => import('@/views/pages/services/generator/Due/Due.vue'),
    meta: {
      title: `${APP_NAME} - Décision Unilatérale de l'Employeur`,
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, SUPERADMIN],

    }

  },
  {
    path: '/services/due/:id',
    name: 'itemServiceDue',
    component: () => import('@/views/pages/services/generator/Due/DueItems.vue'),
    meta: {
      title: `${APP_NAME} - Décision Unilatérale de l'Employeur`,
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, SUPERADMIN],

    }

  },
  {
    path: '/superadmin/validator',
    name: 'validator',
    component: () => import('@/views/pages/admin/WaitingPartners.vue'),
    meta: {
      title: 'Administration - Validation des partenaires',
      rights: [ADMINISTRATOR, SUPERADMIN],

    }
  },
  {
    path: '/services/mandatetude',
    name: 'serviceMandatEtude',
    component: () => import('@/views/pages/services/generator/MandatEtude.vue'),
    meta: {
      title: `${APP_NAME} - Mandat d'étude`,
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, SUPERADMIN],

    }

  },
  {
    path: '/services/parrain',
    name: 'serviceParrain',
    component: () => import('@/views/pages/services/Parrainage.vue'),
    meta: {
      title: `${APP_NAME} - Parrainage`,
      rights: [COLLABORATOR, ADMINISTRATOR, GUEST, MANAGER, SUPERADMIN],
    }

  },
  {
    path: '/services/appointment',
    name: 'serviceAppointment',
    component: () => import('@/views/pages/services/contact/Appointment.vue'),
    meta: {
      title: `${APP_NAME} - Prise de rendez-vous`,
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, SUPERADMIN],
    }

  },
  {
    path: '/services/sponsor',
    name: 'serviceAdminSponsor',
    component: () => import('@/views/pages/services/sponsoring/Sponsoring.vue'),
    meta: {
      title: `${APP_NAME} - Parrainage`,
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, SUPERADMIN],
    }

  },
  {
    path: '/services/extranet',
    name: 'serviceExtranet',
    component: () => import('@/views/pages/services/extranets/Extranet.vue'),
    meta: {
      title: `${APP_NAME} - Extranet`,
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, SUPERADMIN],

    }

  },
  {
    path: '/services/forms',
    name: 'serviceForms',
    component: () => import('@/views/pages/services/forms/Forms.vue'),
    meta: {
      title: `${APP_NAME} - Mes formulaires`,
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, SUPERADMIN],

    }

  },
  {
    path: '/client',
    name: 'client',
    component: () => import('@/views/pages/customers/Customers.vue'),
    meta: {
      title: `${APP_NAME} - Mes clients`,
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, SUPERADMIN],

    }

  },
  {
    path: '/settings/office',
    name: 'settingsOffice',
    component: () => import('@/views/pages/office-settings/OfficeDashboard.vue'),
    meta: {
      title: `${APP_NAME} - Paramètres de mon agence`,
      rights: [ADMINISTRATOR, MANAGER, SUPERADMIN],

    }
  },
  {
    path: '/office',
    name: 'Office',
    component: () => import('@/views/pages/office-settings/office/Office.vue'),
    meta: {
      title: `${APP_NAME} - Paramètres de mon agence`,
      rights: [ADMINISTRATOR, MANAGER, SOCIAL_OFFICE, ACCOUNTANT, SUPERADMIN],

    }
  },
  {
    path: '/lawyer',
    name: 'Lawyer',
    component: () => import('@/views/pages/lawyer/Lawyer.vue'),
    meta: {
      title: `${APP_NAME} - Configuration des documents`,
      rights: [MANAGER_DUE, ADMINISTRATOR, SUPERADMIN],

    }
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('@/views/pages/office-settings/products/Products.vue'),
    meta: {
      title: `${APP_NAME} - Produits de mon agence`,
      rights: [ADMINISTRATOR, SUPERADMIN],

    }
  },
  {
    path: '/partners',
    name: 'Partners',
    component: () => import('@/views/pages/office-settings/partners/Partners.vue'),
    meta: {
      title: `${APP_NAME} - Partenaires de mon agence`,
      rights: [ADMINISTRATOR, SUPERADMIN],

    }
  },
  {
    path: '/commissions',
    name: 'Commissions',
    component: () => import('@/views/pages/office-settings/commissions/Commissions.vue'),
    meta: {
      title: `${APP_NAME} - Commissions de mon agence`,
      rights: [ADMINISTRATOR, SUPERADMIN],

    }
  },
  // {
  //   path: '/vigilance',
  //   name: 'vigilance',
  //   component: () => import('@/views/pages/services/vigilance/Vigilance.vue'),
  //   meta: {
  //     title: 'Vigilance client',
  //     rights: [ADMINISTRATOR],

  //   }
  // },
  {
    path: '/client/configuration',
    name: 'configurationClient',
    component: () => import('@/views/pages/client/ClientSettings.vue'),
    meta: {
      title: 'Mon configurateur',
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, SUPERADMIN],

    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/pages/dashboard/Dashboard.vue'),
    meta: {
      title: `${APP_NAME} - Tableau de bord`,
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, ACCOUNTANT, SOCIAL_OFFICE, SUPERADMIN],
    }
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: {
      title: `${APP_NAME} - Paramètres du compte`,
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, SUPERADMIN],
    }
  },
  {
    path: '/legal/mentions',
    name: 'legalMentions',
    component: () => import('@/views/pages/legal/LegalNotice.vue'),
    meta: {
      title: `${APP_NAME} - Mentions légales`,
      rights: [COLLABORATOR, ADMINISTRATOR, GUEST, MANAGER, SUPERADMIN],
    }
  },
  {
    path: '/legal/privacy',
    name: 'legalPrivacy',
    component: () => import('@/views/pages/legal/PrivacyPolicy.vue'),
    meta: {
      title: `${APP_NAME} - Politique de confidentialité`,
      rights: [COLLABORATOR, ADMINISTRATOR, GUEST, MANAGER, SUPERADMIN],
    }
  },
  {
    path: '/services/table',
    name: 'serviceTable',
    component: () => import('@/views/pages/services/table/Table.vue'),
    meta: {
      title: `${APP_NAME} - Tableau de bord de mon agence`,
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, SUPERADMIN],
    }
  },

  {
    path: '/services/complaints',
    name: 'complaints',
    component: () => import('@/views/pages/services/complaints/Complaints.vue'),
    meta: {
      title: `${APP_NAME} - Réclamations`,
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, SUPERADMIN],

    },
  },
  {
    path: '/services/complaints/items',
    name: 'serviceComplaintsItems',
    component: () => import('@/views/pages/services/complaints/ComplaintsItems.vue'),
    meta: {
      rights: [COLLABORATOR, ADMINISTRATOR, MANAGER, SUPERADMIN],
    }
  },
  {
    path: '/auth/login',
    name: 'pages-login',
    component: () => import('@/views/pages/auth/Login.vue'),
    meta: {
      title: `${APP_NAME} - Connectez-vous !`,
      layout: 'blank',
      dataUser: {
        name: "",
      }
    },
  },

  {
    path: '/auth/register/office',
    name: 'pages-register-office',
    component: () => import('@/views/pages/auth/RegisterOffice.vue'),
    meta: {
      title: `${APP_NAME} - Inscrivez-vous !`,
      layout: 'blank',
    },
  },
  // {
  //   path: '/auth/register/client',
  //   name: 'pages-register-client',
  //   component: () => import('@/views/pages/auth/RegisterClient.vue'),
  //   meta: {
  //     title: `${APP_NAME} - Inscrivez-vous !`,
  //     layout: 'blank',
  //   },
  // },


  {
    path: '/auth/complete',
    name: 'complete',
    component: () => import('@/views/pages/auth/RegisterComplete.vue'),
    meta: {
      title: `${APP_NAME} - Merci pour votre inscription !`,
      layout: 'blank',
    },
  },
  {
    path: '/off',
    name: 'offline',
    component: () => import('@/views/pages/auth/InternetOffline.vue'),
    meta: {
      title: `${APP_NAME} - Une erreur est survenue !`,
      layout: 'blank',
    },
  },
  {
    path: '/session-expired',
    name: 'session-expired',
    component: () => import('@/views/pages/auth/SessionExpired.vue'),
    meta: {
      title: `${APP_NAME} - Votre session a expiré !`,
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/pages/auth/Error.vue'),
    meta: {
      title: `${APP_NAME} - Une erreur est survenue !`,

    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/auth/login', '/auth/register/office', '/auth/register/client',
    '/auth/complete', '/session-expired', '/error-404'
  ];
  const authRequired = !publicPages.includes(to.path);
  const isAuthenticated = store.state.isAuthenticated;
  const requiredRights = to.meta.rights;
  const userRole = store.state.userRole;
  const token = getCookie("jwt");

  if (!token && authRequired) {
    next('/session-expired');
    return;
  }

  if (isAuthenticated && to.path === '/auth/login') {
    next('/dashboard');
    return;
  }

  if (authRequired && !isAuthenticated) {
    next('/auth/login');
    return;
  }

  if (requiredRights && requiredRights.length > 0 && !requiredRights.includes(userRole)) {
    if (userRole === GUEST) {
      next('/services/parrain');
      return;
    }

    next('/error-404');
    return;
  }

  // Récupérer les routes autorisées si ce n'est pas une page publique
  if (authRequired) {
    console.log(store.state)
    let agencyId = null;

    switch (store.state.userRole) {
      case SUPERADMIN:
      case COLLABORATOR:
      case ADMINISTRATOR:
      case MANAGER:
      case ACCOUNTANT:
      case MANAGER_DUE:
        agencyId = store.state.user.agency_id;
        break;
      case SOCIAL_OFFICE:
        agencyId = store.state.user.id_agency;
        break;
      case GUEST:
        agencyId = store.state.company.id_agency;
        break;
      default:
        agencyId = store.state.user.agency_id;
    }

    let authorizedRoutes = [];

    try {
      const response = await fetch(`${config.apiUri}/agencies/${agencyId}/routes`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        store.commit('setAuthorizedRoutes', data);
        authorizedRoutes = data.map(route => route.path);
      } else {
        throw new Error('Failed to fetch authorized routes');
      }
    } catch (error) {
      console.error(error);
    }

    // Vérification des routes autorisées, y compris les routes dynamiques
    const isAuthorizedRoute = authorizedRoutes.some(route => {
      const regex = new RegExp(`^${route.replace(/:\w+/g, '\\w+')}$`);
      return regex.test(to.path);
    });

    if (!isAuthorizedRoute) {
      next('/error-404');
      return;
    }
  }
  next();
});


// router.beforeEach(async (to, from, next) => {
//   const publicPages = ['/auth/login', '/auth/register/office', '/auth/register/client',
//     '/auth/complete', '/session-expired', '/error-404'
//   ];
//   const authRequired = !publicPages.includes(to.path);
//   const isAuthenticated = store.state.isAuthenticated;
//   const requiredRights = to.meta.rights;
//   const userRole = store.state.userRole;
//   const token = getCookie("jwt");

//   if (!token && authRequired) {
//     next('/session-expired');
//     return;
//   }

//   if (isAuthenticated && to.path === '/auth/login') {



//     next('/dashboard');
//     return;
//   }

//   if (authRequired && !isAuthenticated) {
//     next('/auth/login');
//     return;
//   }

//   if (requiredRights && requiredRights.length > 0 && !requiredRights.includes(userRole)) {
//     if (userRole === GUEST) {
//       next('/services/parrain');
//       return;
//     }


//     next('/error-404');
//     return;
//   }

//   // Récupérer les routes autorisées si ce n'est pas une page publique
//   if (authRequired) {
//     console.log(store.state)
//     let agencyId = null;

//     switch (store.state.userRole) {
//       case COLLABORATOR:
//         agencyId = store.state.user.agency_id;
//         break;
//       case ADMINISTRATOR:
//         agencyId = store.state.user.agency_id;
//         break;
//       case MANAGER:
//         agencyId = store.state.user.agency_id;
//         break;
//       case ACCOUNTANT:
//         agencyId = store.state.user.agency_id;
//         break;
//       case SOCIAL_OFFICE:
//         agencyId = store.state.user.id_agency;
//         break;
//       case GUEST:
//         agencyId = store.state.company.id_agency;
//         break;
//       case MANAGER_DUE:
//         agencyId = store.state.user.agency_id;
//         break;
//       default:
//         agencyId = store.state.user.agency_id;
//     }
//     let authorizedRoutes = [];

//     try {
//       const response = await fetch(`${config.apiUri}/agencies/${agencyId}/routes`, {
//         headers: {
//           "Authorization": `Bearer ${token}`
//         }
//       });

//       if (response.ok) {
//         const data = await response.json();
//         store.commit('setAuthorizedRoutes', data);
//         authorizedRoutes = data.map(route => route.path);
//       } else {
//         throw new Error('Failed to fetch authorized routes');
//       }
//     } catch (error) {
//       console.error(error);
//     }

//     if (!authorizedRoutes.includes(to.path)) {
//       next('/error-404');
//       return;
//     }
//   }
//   next();
// });

export default router


//? Function to name a tab with correct value
const DEFAULT_TITLE = 'Mes Services Assurance';
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});
