import Vue from 'vue';
import Vuex from 'vuex';
import {
  jwtDecode
} from 'jwt-decode';
import config from '../views/config/config';
import cookieEntity from '@/utils/cookies';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import {
  socket
} from '@/utils/socket';

Vue.use(Vuex);

Vue.use(Toast, {
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
});

export default new Vuex.Store({
  state: {
    isAuthenticated: false,
    user: null,
    token: null,
    userRole: null,
    authorizedRoutes: [],
  },
  mutations: {
    setAuthState(state, {
      isAuthenticated,
      user,
      token
    }) {
      state.isAuthenticated = isAuthenticated;
      state.user = user;
      state.token = token;

      if (token) {
        const decoded = jwtDecode(token);
        state.userRole = decoded.role;
      } else {
        state.userRole = null;
      }
    },

    setAuthStateGuest(state, {
      isAuthenticated,
      company,
      token
    }) {
      state.isAuthenticated = isAuthenticated;
      state.company = company;
      state.token = token;
      state.userRole = "invite";
    },

    setAuthorizedRoutes(state, routes) {
      state.authorizedRoutes = routes;
    }
  },
  actions: {
    async fetchUser({
      commit
    }) {
      const token = cookieEntity.getCookie("jwt");
      if (token) {
        const decoded = jwtDecode(token);
        const userId = decoded.id;

        switch (this.state.userRole) {
          case "manager":
          case "administrateur":
          case "superadministrateur":
          case "collaborateur":
            try {
              const response = await fetch(`${config.apiUri}/accounts/${userId}`, {
                headers: {
                  "Authorization": `Bearer ${token}`
                }
              });
              const data = await response.json();

              commit('setAuthState', {
                isAuthenticated: true,
                user: data,
                token
              });


              socket.on("connect", () => {
                socket.emit("userConnected",
                  `${this.state.user.lastname} ${this.state.user.firstname}`, userId
                  .toString())
              })

            } catch (error) {
              console.error("Erreur lors de la récupération des données de l'utilisateur",
                error);
            }
            break;
          case "manager due":
          case "expert comptable":
          case "cabinet social":
            try {
              const response = await fetch(`${config.apiUri}/socials/${userId}`, {
                headers: {
                  "Authorization": `Bearer ${token}`
                }
              });
              const data = await response.json();

              commit('setAuthState', {
                isAuthenticated: true,
                user: data,
                token
              });

            } catch (error) {
              console.error("Erreur lors de la récupération des données de l'utilisateur",
                error);
            }
            break;
        }
      }
    },

    async fetchGuest({
      commit
    }) {
      const token = cookieEntity.getCookie("jwt");
      if (token) {
        const decoded = jwtDecode(token);
        const idAgency = decoded.id_agency;
        const idCompany = decoded.id_company;

        try {
          const response = await fetch(
            `${config.apiUri}/agencies/${idAgency}/customers/${idCompany}`, {
              headers: {
                "Authorization": `Bearer ${token}`
              }
            });
          const data = await response.json();

          commit('setAuthStateGuest', {
            isAuthenticated: true,
            company: data,
            userRole: "invite",
            token
          });

        } catch (error) {
          console.error("Erreur lors de la récupération des données de l'utilisateur", error);
        }
      }
    },

    async login({
      commit
    }, {
      userId,
      pincode,
      type,
      password
    }) {
      try {
        const response = await fetch(`${config.apiUri}/auth/verify-pin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            userId: userId,
            pin: pincode,
            type: type,
            password: password
          }),
        });

        if (!response.ok) {
          throw new Error('Échec de la vérification du code PIN. Veuillez réessayer.');
        }

        const data = await response.json();

        if (data && data.token) {
          const time = 14400; // 4 heures
          cookieEntity.setCookie("jwt", data.token, time, true, "Strict");

          commit('setAuthState', {
            isAuthenticated: true,
            user: data.user,
            token: data.token
          });
        } else {
          throw new Error('Données de réponse invalides.');
        }

      } catch (error) {
        console.error('Erreur de connexion:', error.message || error);
        throw new Error('Connexion impossible. Veuillez vérifier vos identifiants.');
      }
    },

    async loginGuest({
      commit
    }, {
      sponsorCode
    }) {
      try {
        const response = await fetch(`${config.apiUri}/auth/login/guest`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            sponsorCode
          }),
        });

        const data = await response.json();

        if (response.ok) {
          const time = 14400;
          cookieEntity.setCookie("jwt", data.token, time, true, "Strict");

          commit('setAuthStateGuest', {
            isAuthenticated: true,
            company: data.company,
            token: data.token
          });

          window.location.href = "/services/parrain";

        } else {
          throw new Error('Connexion impossible. Votre code de parrainage est incorrect.');
        }
      } catch (error) {
        console.error(error);
      }
    },

    async fetchAuthorizedRoutes({
      commit,
      state
    }) {
      if (state.user && state.user.agency_id) {
        try {
          const response = await fetch(
            `${config.apiUri}/agencies/${state.user.agency_id}/routes`, {
              headers: {
                'Authorization': `Bearer ${state.token}`
              }
            });

          if (response.ok) {
            const routes = await response.json();
            commit('setAuthorizedRoutes', routes);
          } else {
            throw new Error('Failed to fetch authorized routes');
          }
        } catch (error) {
          console.error(error);
        }
      }
    },

    logout({
      commit
    }) {
      commit('setAuthState', {
        isAuthenticated: false,
        user: null,
        token: null,
        userRole: null,
      });

      cookieEntity.deleteCookie("jwt");
      cookieEntity.deleteCookie("expiration");
    },
  },
});
