function setCookie(cname, cvalue, exSeconds, secure = true, sameSite = 'Strict') {
  const d = new Date();
  d.setTime(d.getTime() + (exSeconds * 1000));
  let expires = "expires=" + d.toUTCString();
  let cookieText = `${cname}=${encodeURIComponent(cvalue)};${expires};path=/`;

  if (secure) {
    cookieText += ";Secure";
  }

  cookieText += `;SameSite=${sameSite}`;

  document.cookie = cookieText;
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i].trim();
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function deleteCookie(cname) {
  // Définir le cookie avec une date d'expiration dans le passé pour le supprimer
  document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
}


module.exports = {
  setCookie,
  getCookie,
  deleteCookie
}
