<template>
  <v-menu
    offset-x
    right
    nudge-right="20"
    min-width="230"
    content-class="user-profile-menu-content"
    :close-on-content-click="false"
    class="elevation-15"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-if="isAdmin || isCollaborator || isManager || isSocial || isSuperAdmin">
        <v-btn v-if="pictureProfil != null" icon outlined x-large v-bind="attrs" v-on="on">
          <v-badge
            :color="user.available === 1 ? 'EoleGreen' : 'EoleErrorBase'"
            overlap
            bottom
            dot
            bordered
            offset-x="15"
            offset-y="12"
          >
            <v-avatar class="bordered elevation-avatar elevation-avatar" style="">
              <v-img :src="pictureProfil"></v-img>
            </v-avatar>
          </v-badge>
        </v-btn>
        <v-btn v-else icon outlined x-large v-bind="attrs" v-on="on" color="EoleBlueLighten">
          <v-badge
            :color="user.available === 1 ? 'EoleGreen' : 'EoleErrorBase'"
            overlap
            bottom
            dot
            bordered
            offset-x="15"
            offset-y="12"
          >
            <v-avatar>
              <span class="EoleBlueLighten--text">{{ user.firstname[0] }}{{ user.lastname[0] }}</span>
            </v-avatar>
          </v-badge>
        </v-btn>
      </div>
      <div v-else>
        <v-btn icon outlined x-large v-bind="attrs" v-on="on" color="EoleBlueLighten">
          <v-avatar>
            <v-icon>mdi-account</v-icon>
          </v-avatar>
        </v-btn>
      </div>
    </template>

    <v-list width="300px" flat>
      <div class="pb-3 pt-2" v-if="isAdmin || isCollaborator || isManager || isSuperAdmin">
        <v-badge
          v-if="pictureProfil != null"
          bottom
          :color="user.available === 1 ? 'EoleGreen' : 'EoleErrorBase'"
          offset-x="15"
          offset-y="12"
          class="ms-4"
          bordered
          dot
        >
          <v-avatar class="elevation-7" size="52">
            <v-img :src="pictureProfil"></v-img>
          </v-avatar>
        </v-badge>
        <v-badge
          v-else
          bottom
          :color="user.available === 1 ? 'EoleGreen' : 'EoleErrorBase'"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar class="bordered" color="EoleBlue">
            <span class="white--text">{{ user.firstname[0] }}{{ user.lastname[0] }}</span>
          </v-avatar>
        </v-badge>

        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
          <span class="text--primary font-weight-semibold mb-n1">{{ user.firstname }} {{ user.lastname }}</span>
          <small>Cabinet : {{ user.agency_name }}</small>
          <small>Compagnie : {{ user.insurer_name }}</small>
          <small>Licence : {{ user.subscription_designation }}</small>

          <small class="text--disabled text-capitalize">{{ grade }}</small>
        </div>
      </div>
      <div class="pb-3 pt-2 ml-1" v-else-if="isSocial">
        <v-avatar class="bordered" color="EoleBlue">
          <span class="white--text">{{ user.firstname[0] }}{{ user.lastname[0] }}</span>
        </v-avatar>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
          <span class="text--primary font-weight-semibold mb-n1"> {{ user.firstname }} {{ user.lastname }} </span>
          <small>Cabinet social</small>
        </div>
      </div>
      <div class="pb-3 pt-2 ml-1" v-else-if="isGuest">
        <v-avatar class="bordered" color="EoleBlue">
          <v-icon color="white">mdi-account</v-icon>
        </v-avatar>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
          <span class="text--primary font-weight-semibold mb-n1">Invité</span>
          <small>Entreprise : {{ $store.state.company.commercial_name }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <v-list-item
        v-if="isAdmin || isCollaborator || isManager || isSuperAdmin"
        link
        :to="{ name: 'pages-account-settings' }"
      >
        <v-list-item-icon class="me-2">
          <i class="fa-solid fa-circle-user" style="font-size: 1.2rem"></i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Mon Profil</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item link :to="{ name: 'pages-login' }">
        <v-list-item-icon class="me-2">
          <i class="fa-solid fa-right-left" style="font-size: 1.2rem"></i
        ></v-list-item-icon>
        <v-list-item-content @click="logOut()">
          <v-list-item-title>Changer de compte</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mb-2"></v-divider>

      <v-list-item link :to="{ name: 'pages-login' }">
        <v-list-item-icon class="me-2">
          <!-- <v-icon size="22" color="EoleError"> mdi-logout-variant </v-icon> -->
          <i class="fa-solid fa-power-off EoleError--text" style="font-size: 1.2rem"></i>
        </v-list-item-icon>
        <v-list-item-content @click="logOut()">
          <v-list-item-title class="">Déconnexion</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css"
import config from "@/views/config/config"
import moment from "moment"
import { deleteCookie } from "@/utils/cookies"

export default {
  data: () => ({
    deadline3: Date.now() + 1000 * 60 * 60 * 4,

    loadingCountContactNumber: false,
    user: null,
    agent_for: null,
    grade: null,
    agency: null,
    contactNumber: 0,
    pictureProfil: null,
    initial: null,
    lastname: null,
    firstname: null,
    available: null,
    company_name: null,
    isSuperAdmin: false,
    isAdmin: false,
    isCollaborator: false,
    isManager: false,
    isGuest: false,
    isSocial: false,
  }),
  computed: {
    formattedTime() {
      return moment(this.deadline3).format("HH:mm")
    },
  },

  methods: {
    getPictureProfil() {
      try {
        let completeName = `${this.$store.state.user.lastname} ${this.$store.state.user.firstname}`
        let name = completeName.toLowerCase().replace(/ /g, "-")
        let agency = this.$store.state.user.agency_name.toLowerCase().replace(/ /g, "_")
        let pictureProfilPng = `${config.ged}/${agency}/users/${name}/profil.png`

        let pictureProfilJpg = `${config.ged}/${agency}/users/${name}/profil.jpg`

        let img = new Image()
        img.onload = () => {
          this.pictureProfil = img.src
        }
        img.onerror = () => {
          this.pictureProfil = null
        }

        img.src = pictureProfilPng

        setTimeout(() => {
          if (this.pictureProfil == null) {
            img.src = pictureProfilJpg
          }
        }, 500)
      } catch (error) {
        this.pictureProfil = null
      }
    },

    logOut() {
      this.$store.dispatch("logout")
      this.$router.push({ name: "pages-login" })
    },
  },
  created() {
    this.user = this.$store.state.user
    this.$store.state.userRole === "superadministrateur" ? (this.isSuperAdmin = true) : (this.isSuperAdmin = false)
    this.$store.state.userRole === "administrateur" ? (this.isAdmin = true) : (this.isAdmin = false)
    this.$store.state.userRole === "collaborateur" ? (this.isCollaborator = true) : (this.isCollaborator = false)
    this.$store.state.userRole === "manager" ? (this.isManager = true) : (this.isManager = false)
    this.$store.state.userRole === "invite" ? (this.isGuest = true) : (this.isGuest = false)
    this.$store.state.userRole === "cabinet social" ||
    this.$store.state.userRole === "expert comptable" ||
    this.$store.state.userRole === "manager due"
      ? (this.isSocial = true)
      : (this.isSocial = false)

    this.getPictureProfil()

    // this.isCollaborator || this.isAdmin || this.isManager || this.isSocial
    //   ? (this.user = localStorage.getItem("complete_name"))
    //   : null
    // this.isCollaborator || this.isAdmin | this.isManager || this.isSocial
    //   ? (this.lastname = localStorage.getItem("lastname"))
    //   : null
    // this.isCollaborator || this.isAdmin | this.isManager || this.isSocial
    //   ? (this.firstname = localStorage.getItem("firstname"))
    //   : null
    // this.isCollaborator || this.isAdmin | this.isManager ? (this.agent_for = localStorage.getItem("agent_for")) : null
    // this.isCollaborator || this.isAdmin ? this.getContactNumber() : null
    // this.isCollaborator || this.isAdmin | this.isManager ? this.getPictureProfil() : null
    // this.isCollaborator || this.isAdmin | this.isManager ? (this.agency = localStorage.getItem("agency")) : null
    // this.isCollaborator || this.isAdmin | this.isManager
    //   ? (this.available = parseInt(localStorage.getItem("is_available")))
    //   : null
    // this.company_name = localStorage.getItem("company_name")
  },
  setup() {},
}
</script>

<style lang="scss">
.badge-profil {
  height: 2.5rem !important;
  width: 2.5rem !important;
}

.test {
  box-shadow: 5px 5px 5px 5px red($color: red);
}

.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}

.bordered {
  border: 1px solid #ccc;
}
.elevation-avatar {
  box-shadow: 0px 8px 9px -5px rgba(255, 255, 255, 0.1), 0px 15px 22px 2px rgba(255, 255, 255, 0.1),
    0px 6px 28px 5px rgba(255, 255, 255, 0.25) !important;
}
</style>
