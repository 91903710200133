<template>
  <v-fade-transition mode="out-in">
    <v-btn icon large fab @click="$vuetify.theme.dark = !$vuetify.theme.dark"
      ><v-icon>{{ $vuetify.theme.dark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}</v-icon></v-btn
    >
  </v-fade-transition>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from "@mdi/js"

export default {
  setup() {
    return {
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
    }
  },
}
</script>

<style></style>
